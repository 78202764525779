.dialog-connect-wallet {
  .f-dialog-header {
    display: none !important;
  }
  .f-list-connect-wallet {
    .f-list-item {
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 60px;
      padding: 1.5rem;
      font-size: 1.5rem;
      background: #1c2229;
      margin-bottom: 1.5rem !important;
      font-weight: 700;
      &:last-child {
        margin-bottom: 0;
      }
      img {
        max-width:50px;
      }
      &:hover {
        transform: none !important;
        background-color: #1cbcaf !important;
      }
    }
  }
}

.f-dialog-main:before {
  height: 100% !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 10px ip !important;
  background: rgba(0, 0, 0, 0.4) !important;
}
@media screen and (min-width:320px) and (max-width: 768px) {
  .dialog-connect-wallet .f-list-connect-wallet .f-list-item {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: -10px !important;
    height: 50px !important;
    font-size: 20px !important;
    line-height: 29px;
  }
}
.walletconnect-modal__mobile__toggle a {
  color: black;
}
// @media screen and (max-width: 480px) {
//   .dialog-connect-wallet-mobile .f-list-connect-wallet .f-list-item {
//     width: 280px !important;
//     margin-left: -10px !important;
//     margin-top: 0px !important;
//     height: 50px !important;
//     font-size: 24px !important;
//     line-height: 29px;
//   }
// }